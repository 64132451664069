import React from 'react';

const WordLimitModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Word Limit Reached</h2>
        <p>You've hit the 10,000 word limit.</p>
        <p>If you need more, please reach out to{' '}
          <a 
            href="mailto:jeremy@nextslide.io" 
            className="text-blue-500 hover:underline"
          >
            jeremy@nextslide.io
          </a>
        </p>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default WordLimitModal;