import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Teleprompter from './components/Teleprompter';

const App = () => {
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  return (
    <Router>
      <Routes>
        <Route path="/controller/:id" element={<Teleprompter isController={true} />} />
        <Route path="/view/:id" element={<Teleprompter isController={false} />} />
        <Route path="/" element={<Navigate to={`/controller/${generateUniqueId()}`} />} />
      </Routes>
    </Router>
  );
};

export default App;