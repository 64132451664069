import React, { useState, useRef, useEffect, useCallback } from 'react';

const PrompterView = ({ 
  text, 
  position, 
  fontSize, 
  textWidth, 
  showFullScreenButton, 
  isScrolling, 
  scrollSpeed, 
  onViewerWidthChange 
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  const reportWidth = useCallback(() => {
    if (containerRef.current && onViewerWidthChange) {
      const viewerWidth = containerRef.current.clientWidth;
      onViewerWidthChange(viewerWidth);
    }
  }, [onViewerWidthChange]);

  useEffect(() => {
    reportWidth();
    window.addEventListener('resize', reportWidth);

    return () => window.removeEventListener('resize', reportWidth);
  }, [reportWidth]);

  useEffect(() => {
    if (textRef.current && containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const readingLinePosition = containerHeight * 0.25; // 1/4 down the screen
      textRef.current.style.transform = `translateY(${readingLinePosition - position}px)`;
    }
  }, [position]);

  useEffect(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }

    if (isScrolling) {
      scrollIntervalRef.current = setInterval(() => {
        if (textRef.current && containerRef.current) {
          const containerHeight = containerRef.current.clientHeight;
          const readingLinePosition = containerHeight * 0.25;
          const currentPosition = parseFloat(textRef.current.style.transform.split('(')[1]) || 0;
          const newPosition = currentPosition - scrollSpeed * 0.5;
          textRef.current.style.transform = `translateY(${newPosition}px)`;
        }
      }, 16); // 60 FPS
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [isScrolling, scrollSpeed]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  return (
    <div ref={containerRef} className="h-screen flex flex-col bg-black text-white overflow-hidden relative">
      {showFullScreenButton && (
        <button
          onClick={toggleFullScreen}
          className="absolute top-4 right-4 bg-gray-800 text-white px-3 py-2 rounded hover:bg-gray-700 z-10"
        >
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>
      )}
      <div className="flex-grow flex justify-center overflow-hidden relative">
        <div
          className="absolute left-0 w-8 h-8"
          style={{
            top: '25%',
            borderLeft: '40px solid red',
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent'
          }}
        />
        <div
          className="relative"
          style={{ width: `${textWidth}px` }}
        >
          <div
            ref={textRef}
            className="absolute top-0 left-0 whitespace-pre-wrap text-left"
            style={{
              fontSize: `${fontSize}px`,
              lineHeight: '1.5',
            }}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrompterView;