import React, { useState, useEffect } from 'react';
import LinkCopiedModal from './LinkCopiedModal';
import InfoModal from './InfoModal';

const Toolbar = ({ 
  fontSize, 
  setFontSize, 
  textWidth,
  setTextWidth,
  viewerWidth,
  controllerSetWidth,
  scrollSpeed, 
  setScrollSpeed, 
  isScrolling, 
  setIsScrolling, 
  shareableLink,
  onBackToEdit,
  onToggleFullScreen,
  controllerScreenWidth
}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [localFontSize, setLocalFontSize] = useState(fontSize);

  useEffect(() => {
    setLocalFontSize(fontSize);
  }, [fontSize]);

  const handleFontSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    if (!isNaN(newSize) && newSize > 0) {
      setLocalFontSize(newSize);
      setFontSize(newSize);
    }
  };

  const handleTextWidthChange = (e) => {
    const newWidth = Math.min(parseInt(e.target.value, 10), controllerScreenWidth);
    setTextWidth(newWidth);
  };

  const adjustFontSize = (amount) => {
    const newSize = Math.max(1, localFontSize + amount);
    setLocalFontSize(newSize);
    setFontSize(newSize);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink).then(() => {
      setIsLinkModalOpen(true);
    });
  };

  const buttonClass = "bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600";
  const inputClass = "bg-gray-700 text-white p-1 rounded";

  return (
    <>
      <div className="p-4 bg-gray-800 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <label className="text-sm text-white flex items-center">
            Font Size:
            <button 
              onClick={() => adjustFontSize(-2)} 
              className={`ml-2 px-2 py-1 ${buttonClass}`}
            >
              -
            </button>
            <input
              type="number"
              value={localFontSize}
              onChange={handleFontSizeChange}
              className={`mx-2 w-16 text-center ${inputClass}`}
            />
            <button 
              onClick={() => adjustFontSize(2)} 
              className={`px-2 py-1 ${buttonClass}`}
            >
              +
            </button>
          </label>
          <label className="text-sm text-white flex items-center">
            Text Width:
            <input
              type="range"
              min="200"
              max={controllerScreenWidth}
              value={controllerSetWidth}
              onChange={handleTextWidthChange}
              className="ml-2 w-40"
            />
            <span className="ml-2">{controllerSetWidth}px</span>
            {viewerWidth && viewerWidth < controllerSetWidth && (
              <span className="ml-2 text-yellow-500">
                (Viewer: {viewerWidth}px)
              </span>
            )}
          </label>
          <label className="text-sm text-white">
            Speed:
            <input
              type="range"
              min="0"
              max="5"
              step="0.1"
              value={scrollSpeed}
              onChange={(e) => setScrollSpeed(Number(e.target.value))}
              className="ml-2"
            />
            <span className="ml-2">{scrollSpeed.toFixed(1)}</span>
          </label>
          <button
            onClick={() => setIsScrolling(!isScrolling)}
            className={buttonClass}
          >
            {isScrolling ? 'Stop' : 'Start'}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsInfoModalOpen(true)}
            className={`${buttonClass} bg-gray-600 hover:bg-gray-700`}
            aria-label="Information"
          >
            ?
          </button>
          <button
            onClick={onToggleFullScreen}
            className={buttonClass}
          >
            Full Screen
          </button>
          <button
            onClick={copyToClipboard}
            className={buttonClass}
          >
            Copy View Link
          </button>
          <button
            onClick={onBackToEdit}
            className={buttonClass}
          >
            Back to Edit
          </button>
        </div>
      </div>
      <LinkCopiedModal 
        isOpen={isLinkModalOpen} 
        onClose={() => setIsLinkModalOpen(false)} 
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
    </>
  );
};

export default Toolbar;