import React, { useState, useEffect } from 'react';
import Toolbar from './Toolbar';
import PrompterView from './PrompterView';

const PrompterController = ({
  text,
  position,
  setPosition,
  fontSize,
  setFontSize,
  textWidth,
  setTextWidth,
  controllerSetWidth,
  scrollSpeed,
  setScrollSpeed,
  isScrolling,
  setIsScrolling,
  viewerWidth,
  shareableLink,
  onBackToEdit
}) => {
  const [controllerScreenWidth, setControllerScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setControllerScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  return (
    <div className="h-screen flex flex-col bg-black text-white overflow-hidden">
      <div className="flex-shrink-0">
        <Toolbar
          fontSize={fontSize}
          setFontSize={setFontSize}
          textWidth={textWidth}
          setTextWidth={setTextWidth}
          controllerSetWidth={controllerSetWidth}
          viewerWidth={viewerWidth}
          scrollSpeed={scrollSpeed}
          setScrollSpeed={setScrollSpeed}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          shareableLink={shareableLink}
          onBackToEdit={onBackToEdit}
          onToggleFullScreen={toggleFullScreen}
          controllerScreenWidth={controllerScreenWidth} // Pass the controller's screen width
        />
      </div>
      <div className="flex-grow overflow-hidden">
        <PrompterView 
          text={text} 
          position={position}
          fontSize={fontSize} 
          textWidth={textWidth}
          showFullScreenButton={false}
          isScrolling={isScrolling}
          scrollSpeed={scrollSpeed}
        />
      </div>
    </div>
  );
};

export default PrompterController;