import React, { useState } from 'react';
import MathCaptcha from './MathCaptcha';
import WordLimitModal from './WordLimitModal';

const WORD_LIMIT = 10000;

const ScriptInput = ({ text, setText, onSubmit }) => {
  const [showLimitModal, setShowLimitModal] = useState(false);

  const wordCount = text.trim() ? text.trim().split(/\s+/).length : 0;
  const readingTimeMinutes = wordCount > 0 ? Math.ceil(wordCount / 150) : 0;

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const newWordCount = newText.trim() ? newText.trim().split(/\s+/).length : 0;
    
    if (newWordCount <= WORD_LIMIT) {
      setText(newText);
    } else {
      setShowLimitModal(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <h1 className="text-3xl font-bold mb-4">Teleprompter Script Input</h1>
      <div className="mb-4">
        <p className="text-sm text-gray-600">
          Word Count: {wordCount}/{WORD_LIMIT} | 
          Estimated Reading Time: {readingTimeMinutes} minute{readingTimeMinutes !== 1 ? 's' : ''}
        </p>
      </div>
      <textarea
        value={text}
        onChange={handleTextChange}
        className="w-full h-96 bg-white text-gray-800 p-4 mb-4 rounded border"
        placeholder="Paste your script here..."
      />
      <MathCaptcha onSubmit={onSubmit} />
      <WordLimitModal isOpen={showLimitModal} onClose={() => setShowLimitModal(false)} />
    </div>
  );
};

export default ScriptInput;