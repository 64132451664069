import React, { useState, useEffect } from 'react';

const MathCaptcha = ({ onSubmit }) => {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    generateNewProblem();
  }, []);

  const generateNewProblem = () => {
    setNum1(Math.floor(Math.random() * 10) + 1);
    setNum2(Math.floor(Math.random() * 10) + 1);
    setUserAnswer('');
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(userAnswer) === num1 + num2) {
      onSubmit();
    } else {
      setError('Incorrect answer. Please try again.');
      generateNewProblem();
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <span className="text-lg">{num1} + {num2} = </span>
      <input
        type="number"
        value={userAnswer}
        onChange={(e) => setUserAnswer(e.target.value)}
        className="border rounded px-2 py-1 w-16"
        required
      />
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Start Prompter
      </button>
      {error && <span className="text-red-500 ml-2">{error}</span>}
    </div>
  );
};

export default MathCaptcha;